import { getStatusColor, capitalizeFirstLetter } from "../utils/utils";

type Props = {
  status: string;
};

const StatusBadge = ({ status }: Props) => {
  const capitalizedStatus = capitalizeFirstLetter(status);
  const statusClassNames = getStatusColor(capitalizedStatus);

  return (
    <div
      className={`min-w-[100px] inline-block rounded-full border border-solid py-1 px-4 text-center whitespace-nowrap ${statusClassNames}`}
    >
      <div>{capitalizedStatus}</div>
    </div>
  );
};

export default StatusBadge;
