import { getImageUrl } from "~/utils/utils";
import { RecipeImage } from "../../../components/ImagePicker/ImageGrid";
import { RecipeStep } from "../types";
import placeholder from "~/assets/placeholder_ingredients.jpg";
import { FileWithPath } from "react-dropzone";

const getIdRegex = /(?<=\.com\/)[^\/]+(?=\.(jpg|png))/;
export const getImageId = (image: RecipeImage) => {
  return image?.url?.match(getIdRegex)?.[0];
};

function getFinalDigits(imageName: string) {
  const match = imageName?.match(/_(\d{2})(\.jpg|\.jpeg|\.png)$/i);
  return match ? parseInt(match[1], 10) : -1;
}

export function sortByFinalDigits(images: RecipeImage[]) {
  return images?.sort(
    (a: RecipeImage, b: RecipeImage) =>
      getFinalDigits(a.name ?? "") - getFinalDigits(b.name ?? "")
  );
}

export const getStepImages = (steps: RecipeStep[]) =>
  steps
    .filter(({ image }) => image)
    .map((step) => ({
      ...step.image,
      fromTemplate: step.fromTemplate,
    }));

export const getImage = (image: RecipeImage) => {
  if (image.url) {
    return getImageUrl(image.url);
  } else if (image.previewUrl) {
    return image.previewUrl;
  } else {
    return placeholder;
  }
};

export const downloadImage = (image: RecipeImage) =>
  fetch(getImageUrl(image.url))
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", image.name ?? "");
      document.body.appendChild(link);
      link.click();
    });

export function checkFileNameMatch(files: FileWithPath[], gdocs: string) {
  return files.some((file) => {
    const match = file.path?.match(/_(\d{4})_/);
    return match && match[1] === gdocs;
  });
}
