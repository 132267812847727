import { useEffect } from "react";

const useScrollToTop = (isFormOpen: boolean) => {
  useEffect(() => {
    if (isFormOpen) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [isFormOpen]);
};

export default useScrollToTop;
