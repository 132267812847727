type Props = {
  onClick?: () => void;
  isDisabled?: boolean;
  text?: string;
};

const CancelBtn = ({ onClick, isDisabled, text = "Cancel" }: Props) => (
  <button
    className="basic-transition bg-transparent font-Alianza700 uppercase tracking-wider text-primary-100   hover:opacity-30"
    onClick={onClick}
    disabled={isDisabled}
    type="button"
  >
    {text}
  </button>
);

export default CancelBtn;
