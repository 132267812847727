import { useEffect } from "react";
import { toast } from "sonner";
import { successCodes } from "~/server/utils";
import { Toast } from "~/types";

export type ToastMessage = {
  success: boolean;
  msg: string;
};

export const createErrorToast = (msg: string): ToastMessage => ({
  success: false,
  msg,
});

export const createSuccessToast = (msg: string): ToastMessage => ({
  success: true,
  msg,
});

const useToast = (toastMsg: Toast | ToastMessage | undefined) => {
  useEffect(() => {
    if (toastMsg && "status" in toastMsg) {
      if ((successCodes as readonly number[]).includes(toastMsg.status)) {
        toast.success(toastMsg.msg);
      } else {
        toast.error(toastMsg.msg);
      }
    } else if (toastMsg) {
      if (toastMsg.success) {
        toast.success(toastMsg.msg);
      } else {
        toast.error(toastMsg.msg);
      }
    }
  }, [toastMsg]);
};

export default useToast;
