import { useState, useEffect, useRef } from "react";

function useSticky(): [
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLDivElement>,
  boolean
] {
  const [hasPassed, setHasPassed] = useState(false);
  const elementARef = useRef<HTMLDivElement>(null);
  const elementBRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (elementARef.current && elementBRef.current) {
      const elementA = elementARef.current.getBoundingClientRect();
      const elementB = elementBRef.current.getBoundingClientRect();

      setHasPassed(elementB.top >= elementA.bottom);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return [elementARef, elementBRef, hasPassed];
}

export default useSticky;
