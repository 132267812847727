import { useEffect, useState } from "react";
import { toast } from "sonner";
import { successCodes } from "~/server/utils";
import { Toast } from "~/types";
import { ToastMessage } from "./useToast";

const useToastWithState = () => {
  const [toastMsg, setToastMessage] = useState<
    Toast | ToastMessage | undefined
  >();

  useEffect(() => {
    if (toastMsg && "status" in toastMsg) {
      if ((successCodes as readonly number[]).includes(toastMsg.status)) {
        toast.success(toastMsg.msg);
      } else {
        toast.error(toastMsg.msg);
      }
    } else if (toastMsg) {
      if (toastMsg.success) {
        toast.success(toastMsg.msg);
      } else {
        toast.error(toastMsg.msg);
      }
    }
  }, [toastMsg]);

  return setToastMessage;
};

export default useToastWithState;
