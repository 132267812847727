import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  isExpanded: boolean;
  width?: string;
};

const Accordion = ({
  children,
  isExpanded,
  width = "col-span-full",
}: Props) => (
  <div
    className={`accordion ${width} ${isExpanded ? "expanded" : "collapsed"}`}
  >
    {children}
  </div>
);

export default Accordion;
