import { forwardRef, SelectHTMLAttributes } from "react";

type Props = {
  className?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ className, ...props }, ref) => (
    <select ref={ref} className="input-styles" {...props} />
  )
);

export default Select;
