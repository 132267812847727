import { Backdrop as MUIBackdrop, CircularProgress } from "@mui/material";

type Props = {
  open: boolean;
  onClick?: () => void;
};

const Backdrop = ({ open, onClick }: Props) => {
  return (
    <div>
      <MUIBackdrop
        open={open}
        sx={(theme) => ({ color: "#f45e45", zIndex: theme.zIndex.drawer + 1 })}
        onClick={onClick}
      >
        <CircularProgress color="inherit" />
      </MUIBackdrop>
    </div>
  );
};

export default Backdrop;
