import { PrimaryBtn } from ".";

type Props = {
  onClick: () => void;
  text?: string;
  isDisabled?: boolean;
};

const CreateBtn = ({ onClick, text = "Create", isDisabled }: Props) => {
  return <PrimaryBtn text={text} onClick={onClick} disabled={isDisabled} />;
};

export default CreateBtn;
