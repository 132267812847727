import { HTMLAttributes } from "react";

type Props = {
  gap?: string;
  height?: string;
} & HTMLAttributes<HTMLDivElement>;

const InputWrapper = ({
  gap,
  className,
  height = "h-[45px]",
  ...props
}: Props) => (
  <div
    className={`flex ${height} flex-wrap items-center ${
      gap ? gap : "gap-2"
    } mr-5 ${className}`}
    {...props}
  />
);

export default InputWrapper;
