import { IsObject } from "~/utils/tsUtils";
import { logRequest } from "~/utils/utils";

export type FailureResponse = { msg: string; status: 400 };
export type SuccessResponse = {
  msg: string;
  status: 200;
  id: string | undefined;
};
export type RequestResponse = SuccessResponse | FailureResponse;

// TODO: We use a lot of type assertions here to be as close as possible to the original code
// We could also use Zod here to validate the response data -- it would make the code more readable
// and easier to understand. But we would have to know the exact shape of the response data.
export const executeRequest = async (
  deferredRequest: () => Promise<unknown>,
  throwHandler: (error: unknown) => FailureResponse,
  defaultSuccessMsg: string,
  errorHandler?: (res: unknown) => FailureResponse
): Promise<RequestResponse> => {
  try {
    // With the current API functions, data should be axiosResponse.data
    // It is only returned by Axios when the status code is in the 200s
    const res = await deferredRequest();

    if (!IsObject(res)) {
      // Data can be falsy (""/undefined/null aka empty body) if response was 204
      // We cannot check the status code since the current API functions only return the data
      // So we assume that an empty body means success and show "Data updated"
      // If the data is truthy, it's a primitive value and we cast it to string
      return {
        msg: res ? res.toString() : "Data Updated",
        status: 200,
        id: undefined,
      };
    }

    // code for brands new page
    let brandId: string | undefined;
    if ("brands" in res && Array.isArray(res.brands)) {
      const lastIndex = res.brands.length - 1;
      if (
        IsObject(res.brands[lastIndex]) &&
        "id" in res.brands[lastIndex] &&
        typeof res.brands[lastIndex].id === "string"
      ) {
        brandId = res.brands[lastIndex].id;
      }
    }

    // if it an error, is 400 (except 403) throw Error
    if (
      "status" in res &&
      typeof res.status === "number" &&
      res.status >= 400 &&
      res.status !== 403
    ) {
      throw res;
    }

    // no status if successful
    if (!("status" in res) || !res.status || typeof res.status === "string") {
      const uuid =
        "uuid" in res && typeof res.uuid === "string" && res.uuid
          ? res.uuid
          : undefined;
      const id =
        "id" in res && typeof res.id === "string" && res.id
          ? res.id
          : undefined;
      const responseId = brandId ?? uuid ?? id;
      return {
        msg: defaultSuccessMsg || "Data Updated",
        status: 200,
        id: responseId,
      };
    }
    if (errorHandler !== undefined) {
      return errorHandler(res);
    }
    return getErrorHandler("Error. Got response: " + JSON.stringify(res))(res);
  } catch (error) {
    logRequest(error);
    return throwHandler(error);
  }
};

export const getErrorHandler =
  (msg?: string) =>
  (_res: unknown): FailureResponse => {
    const finalMsg = msg ?? "In Use. Unable to Delete";
    return {
      msg: finalMsg,
      status: 400,
    } as const;
  };

export const getThrowHandler = function (
  msg?: string
): (error: unknown) => FailureResponse {
  return (error: unknown) => {
    function getResponseMessage() {
      if (!IsObject(error)) {
        return undefined;
      }
      const responseMessage =
        "response" in error &&
        IsObject(error.response) &&
        "data" in error.response &&
        IsObject(error.response.data) &&
        "message" in error.response.data &&
        typeof error.response.data.message == "string"
          ? error.response.data.message
          : undefined;
      return responseMessage;
    }
    function getResponseError() {
      if (!IsObject(error)) {
        return undefined;
      }
      const responseMessage =
        "response" in error &&
        IsObject(error.response) &&
        "data" in error.response &&
        IsObject(error.response.data) &&
        "error" in error.response.data &&
        typeof error.response.data.error == "string"
          ? error.response.data.error
          : undefined;
      return responseMessage;
    }
    function getResponseMessageData() {
      if (!IsObject(error)) {
        return undefined;
      }
      const responseMessage =
        "data" in error &&
        IsObject(error.data) &&
        "message" in error.data &&
        typeof error.data.message == "string"
          ? error.data.message
          : undefined;
      return responseMessage;
    }
    function getErrorMessage() {
      if (error instanceof Error) {
        return error.message;
      }
    }


    const finalMsg: string = msg ||
      getResponseMessage() ||
      getResponseError() ||
      getResponseMessageData() ||
      getErrorMessage() ||
      "Action Failed. Please Try Again";

    return {
      msg: finalMsg,
      status: 400,
    };
  };
};
