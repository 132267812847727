import { forwardRef, TextareaHTMLAttributes } from "react";

type Props = {
  className?: string;
  rows?: number;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, rows = 3, ...props }, ref) => (
    <textarea
      className={`textarea-styles ${className}`}
      ref={ref}
      rows={rows}
      {...props}
    />
  )
);

export default TextArea;
