import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MouseEventHandler } from "react";

type Props = {
  onClick: MouseEventHandler<HTMLDivElement>;
};

const MoreButtonVertical = ({ onClick }: Props) => (
  <div onClick={onClick}>
    <MoreVertIcon
      className="cursor-pointer p-1 text-primary-100"
      style={{
        width: "36px",
        height: "40px",
      }}
    />
  </div>
);
export default MoreButtonVertical;
