import CTAModal from "./CTAModal";
import { PrimaryBtn } from "../Buttons";

const AlertModal = ({ isOpen, onClose, text }) => {
  return (
    <CTAModal isOpen={isOpen} text={text} method="get">
      <PrimaryBtn text="OK" type={onClose ? "button" : "submit"} onClick={onClose} />
    </CTAModal>
  );
};

export default AlertModal;
