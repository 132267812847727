import { Dialog } from "@mui/material";
import { useNavigate } from "@remix-run/react";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  scroll?: any;
  onClose: () => void;
};

const Modal = ({ children, isOpen, className, scroll, onClose }: Props) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={isOpen}
      className={className}
      scroll={scroll}
      onClose={() => (onClose ? onClose() : navigate(-1))}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
