import PrimaryBtn from "./PrimaryBtn";

type Props = {
  onClick?: () => void;
  name?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
};

const DeleteBtn = ({ isSubmitting, disabled, onClick, name }: Props) => (
  <PrimaryBtn
    text={isSubmitting ? "Deleting..." : "Delete"}
    disabled={disabled || isSubmitting}
    type="submit"
    onClick={onClick}
    elementName={name}
  />
);

export default DeleteBtn;
