import { forwardRef } from "react";

type InputProps = React.HTMLProps<HTMLInputElement> & {
  icon?: React.ReactElement;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      value,
      name,
      label,
      className = "",
      readOnly,
      onChange,
      placeholder,
      icon,
      ...props
    },
    ref
  ): React.ReactElement => {
    return (
      <>
        <input
          className={`input-styles ${className}`}
          ref={ref}
          type={type}
          value={value}
          readOnly={readOnly}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          {...props}
        />
        {icon && <div className="absolute right-2 top-11">{icon}</div>}
      </>
    );
  }
);

export default Input;
