import { CancelBtn, SaveBtn } from "..";
import { useFormState } from "react-hook-form";

type Props = {
  onCancel: () => void;
  onSave?: () => void;
  isDisabled: boolean;
  isSubmitting?: boolean;
};

const FormButtons = ({ onCancel, onSave, isDisabled, isSubmitting }: Props) => {
  const { dirtyFields, isValid } = useFormState();
  const isDirty = () => isValid && Object.keys(dirtyFields).length > 0;

  return (
    <div className="form__buttons">
      <CancelBtn onClick={onCancel} />
      <SaveBtn
        onClick={onSave}
        disabled={isDisabled || !isDirty()}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};
export default FormButtons;
