type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  form?: string;
  type?: "button" | "submit";
  name?: string;
  defaultValue?: string;
  text?: string;
  value?: string;
  className?: string;
  elementName?: string;
};

const PrimaryBtn = ({
  onClick,
  disabled,
  text,
  type = "button",
  form,
  defaultValue,
  name,
  value,
  className,
  elementName,
}: Props) => (
  <button
    className={`basic-transition min-w-[180px] cursor-pointer rounded border border-solid border-primary-100 bg-primary-100 px-6 py-[7px] font-Alianza700 uppercase tracking-wider text-bgColor shadow-md hover:opacity-70 disabled:opacity-50 ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={type}
    form={form}
    name={name}
    value={value}
    defaultValue={defaultValue}
  >
    {elementName ? `Delete ${elementName}` : text}
  </button>
);

export default PrimaryBtn;
