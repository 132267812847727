import PrimaryBtn from "./PrimaryBtn";

type Props = {
  onClick?: () => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  form?: string;
  name?: string;
  text?: string;
  value?: string;
  className?: string;
};

const SaveBtn = ({
  onClick,
  isSubmitting,
  disabled,
  form,
  name,
  text = isSubmitting ? "Saving..." : "Save",
  value,
  className,
}: Props) => {
  return (
    <PrimaryBtn
      text={text}
      onClick={onClick}
      disabled={disabled || isSubmitting}
      type="submit"
      form={form}
      name={name}
      value={value}
      className={className}
    />
  );
};

export default SaveBtn;
