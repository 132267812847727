import { Link } from "@remix-run/react";

type Props = {
  to: string;
  target?: string;
  children: React.ReactNode;
};

const BasicBtnLink = ({ to, target = "_self", children }: Props) => (
  <Link
    className={`max-h-10 rounded border border-primary-100 px-2 py-1 font-Alianza700 text-sm uppercase tracking-wider text-primary-100  duration-100 hover:bg-primary-100 hover:text-bgColor`}
    to={to}
    target={target}
  >
    {children}
  </Link>
);

export default BasicBtnLink;
