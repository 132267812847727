import { forwardRef, HTMLAttributes } from "react";

type FieldProps = HTMLAttributes<HTMLDivElement>;

const Field = forwardRef<HTMLDivElement, FieldProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={`flex flex-col gap-2.5 self-start ${className}`}
        {...props}
        ref={ref}
      />
    );
  }
);

export default Field;
