import { HTMLAttributes } from "react";

type Props = {
  className?: string;
  isLowerCase?: boolean;
} & HTMLAttributes<HTMLFieldSetElement>;

const RadioGroup = ({ className, isLowerCase = false, ...props }: Props) => (
  <fieldset
    className={`flex gap-2.5 ${isLowerCase ? "lowercase" : ""} ${className} `}
    {...props}
  />
);

export default RadioGroup;
