import { getImage } from "../../features/Recipes/Images/utils";
import type { Image } from "~/types";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RecipeImage } from "./ImageGrid";

type Props = {
  image: RecipeImage;
  isDisabled: boolean;
  handleDownload: (image: RecipeImage) => void;
  handleDeleteClick: (image: RecipeImage) => void;
};

const ImageContainer = ({
  image,
  isDisabled,
  handleDownload,
  handleDeleteClick,
}: Props) => (
  <div className="flex gap-6 border border-primary-200 border-dashed rounded-lg shadow-md p-2">
    <Image image={image} />
    <ImageDetails
      image={image}
      isDisabled={isDisabled}
      handleDownload={handleDownload}
      handleDeleteClick={handleDeleteClick}
    />
  </div>
);

type ImageProps = {
  image: RecipeImage;
};

const Image = ({ image }: ImageProps) => (
  <div className={`shrink-0 h-[80px]`}>
    <img
      src={image ? getImage(image) : undefined}
      className={`h-full w-auto rounded object-cover ${
        image?.fromTemplate
          ? "border-[3px] border-solid border-[#f45e45]"
          : null
      }`}
      alt="file-preview"
    />
  </div>
);

type ImageDetailsProps = {
  image: RecipeImage;
  isDisabled: boolean;
  handleDownload: (file: Image) => void;
  handleDeleteClick: (image: RecipeImage) => void;
};

const ImageDetails = ({
  image,
  isDisabled,
  handleDownload,
  handleDeleteClick,
}: ImageDetailsProps) => (
  <div className="flex flex-col text-base">
    <div className="font-bold break-all">{image.name}</div>
    <div className="flex items-center gap-2 mt-3">
      <DownloadIcon
        className="cursor-pointer hover:text-primary-100"
        onClick={() => handleDownload(image)}
      />
      <a href={image.url ?? ""} target="_blank" className="inline-block">
        <VisibilityIcon className="cursor-pointer hover:text-primary-100" />
      </a>
      {isDisabled ? null : (
        <DeleteIcon
          className="cursor-pointer hover:text-primary-100"
          onClick={() => handleDeleteClick(image)}
        />
      )}
    </div>
  </div>
);

export default ImageContainer;
