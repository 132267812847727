import { useState } from "react";

import Backdrop from "~/components/Backdrop/Backdrop";

type UseBackdropReturn = [
  React.Dispatch<React.SetStateAction<boolean>>,
  () => JSX.Element,
];

const useBackdrop = (): UseBackdropReturn => {
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleClose = () => {
    setShowBackdrop(false);
  };

  const BackdropComponent = () => (
    <Backdrop open={showBackdrop} onClick={handleClose} />
  );

  return [setShowBackdrop, BackdropComponent];
};

export default useBackdrop;
