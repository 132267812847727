import { RecipeImage } from "./ImageGrid";
import { Image } from "~/types";
import { getImageUrl } from "~/utils/utils";

export const downloadImage = (image: RecipeImage | Image) => {
  fetch(getImageUrl(image.url))
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", image.name ?? "");
      document.body.appendChild(link);
      link.click();
    });
};

export const generateDeleteMsg = (image: RecipeImage) =>
  image.fromTemplate
    ? "This image is from a step template. If you delete it, you will break the connection to it. Delete it anyway?"
    : `Are you sure you want to delete ${image?.name ?? "this image"}?`;
