import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { CancelBtn, DeleteBtn } from "..";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  handleDelete: () => void;
};

const DeleteConfirm = ({ isOpen, onClose, message, handleDelete }: Props) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <div className="form__buttons">
        <CancelBtn onClick={onClose} />
        <DeleteBtn onClick={handleDelete} />
      </div>
    </DialogActions>
  </Dialog>
);

export default DeleteConfirm;
