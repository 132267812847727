import React, { HTMLAttributes } from "react";

type ErrorsProps = HTMLAttributes<HTMLDivElement>;

const Errors: React.FC = (props: ErrorsProps) => {
  return (
    <div className="col-span-12 flex flex-col gap-2.5 text-center" {...props} />
  );
};

export default Errors;
