type Props = {
  onClick?: () => void;
  text: string;
  className?: string;
};

const BasicBtn = ({ onClick, text, className }: Props) => (
  <button
    className={` max-h-10 px-2 py-1 font-Alianza700 text-sm uppercase tracking-wider text-primary-100 border border-primary-100 rounded-md ${className} duration-100 hover:rounded hover:bg-primary-100 hover:text-bgColor`}
    onClick={onClick}
    type="button"
  >
    {text}
  </button>
);

export default BasicBtn;
