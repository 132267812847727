import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  handleCancel: () => void;
  title: string;
};

const FormHeader = ({ title, handleCancel }: Props) => (
  <div className="flex items-center justify-between font-Alianza700 text-xl mb-4">
    <div className=" text-primary-100">{title}</div>
    <HighlightOffIcon
      onClick={handleCancel}
      className="cursor-pointer hover:text-primary-100"
    />
  </div>
);

export default FormHeader;
