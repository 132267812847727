import { forwardRef, InputHTMLAttributes } from "react";
import { Label } from "../FormComponents";

type Props = {
  type?: string;
  className?: string;
  onChange?: () => void;
  checked?: boolean;
  labelText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Radio = forwardRef<HTMLInputElement, Props>(
  (
    { type = "radio", className, onChange, checked, labelText, ...props },
    ref
  ) => (
    <div className={className}>
      {labelText && <Label>{labelText}</Label>}
      <input
        className="h-4 w-4 shrink-0 cursor-pointer rounded-full accent-primary-100 checked:text-primary-100 bg-white bg-opacity-50"
        ref={ref}
        type={type}
        onChange={onChange}
        checked={checked}
        {...props}
      />
    </div>
  )
);

export default Radio;
