import { HTMLAttributes, ReactNode } from "react";

type Props = {
  className?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLLabelElement>;

const Label = ({ className = "mb-0", children, ...props }: Props) => {
  return (
    <label className={className} {...props}>
      {children}
    </label>
  );
};

export default Label;
