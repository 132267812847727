type Props = {
  onClick: () => void;
  disabled?: boolean;
  isSubmitting?: boolean;
  text: string;
  type?: "button" | "submit" | "reset";
  defaultValue?: string;
  name?: string;
  value?: string;
  children?: React.ReactNode;
};

const SecondaryBtn = ({
  onClick,
  text,
  type = "button",
  disabled,
  defaultValue,
  name,
  value,
  isSubmitting,
  children,
}: Props) => (
  <button
    className="basic-transition flex items-center justify-center rounded bg-bgColor text-primary-100 px-3 py-1 font-Alianza700 font-semibold tracking-widest uppercase hover:bg-primary-100 hover:text-bgColor border border-primary-100 "
    name={name}
    onClick={onClick}
    type={type}
    disabled={disabled || isSubmitting}
    defaultValue={defaultValue}
    value={value}
  >
    {text} {children}
  </button>
);

export default SecondaryBtn;
