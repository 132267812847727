import React, { forwardRef, InputHTMLAttributes } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ type = "checkbox", className, isDisabled, ...props }, ref) => (
    <input
      className="my-2.5 flex h-4 w-4 cursor-pointer flex-col checked:accent-primary-100 bg-white bg-opacity-50"
      ref={ref}
      type={type}
      disabled={isDisabled}
      {...props}
    />
  )
);

export default Checkbox;
