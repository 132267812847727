import { useState } from "react";
import { useRevalidator } from "@remix-run/react";
import { SecondaryBtn } from ".";
import { ApiResponse, createToastMessage } from "~/server/utils";
import useToastWithState from "~/hooks/useToastWithState";

type Props = {
  lokaliseFn: () => Promise<ApiResponse<undefined>>;
};

const LokaliseButton = ({ lokaliseFn }: Props) => {
  const { revalidate } = useRevalidator();

  const [isFetching, setIsFetching] = useState(false);
  const showToast = useToastWithState();

  const handleLokalise = async () => {
    setIsFetching(true);
    const res = await lokaliseFn();
    const toastMessage = createToastMessage(res);

    if (!res.failure) {
      revalidate();
    }

    showToast(toastMessage);
    setIsFetching(false);
  };

  return (
    <SecondaryBtn
      text={isFetching ? "Fetching..." : "Localise"}
      onClick={handleLokalise}
      disabled={isFetching}
    />
  );
};

export default LokaliseButton;
