import { useEffect, useMemo } from "react";
import { useNavigation, useActionData, useRevalidator } from "@remix-run/react";
import { getIsActionRedirect, getIsActionReload } from "../utils/utils";

const useCloseOnReload = (onClose: () => void) => {
  const transition = useNavigation();
  const revalidator = useRevalidator();
  // Use remix v1 typing, that defaults to `any` if no type is provided
  const actionData = useActionData<any>();

  const isReloading = useMemo(
    () => getIsActionRedirect(transition) || getIsActionReload(transition),
    [transition]
  );

  const isRevalidating = useMemo(
    () => revalidator.state === "loading",
    [revalidator.state]
  );

  const wasActionSuccessful = useMemo(
    () => actionData?.success !== false,
    [actionData]
  );

  useEffect(() => {
    if ((isReloading || isRevalidating) && wasActionSuccessful) {
      onClose();
    }
  }, [isReloading, isRevalidating, wasActionSuccessful, onClose]);
};

export default useCloseOnReload;
