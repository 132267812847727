import LangSelect from "~/components/Select/LangSelect";
import Navbar from "~/layouts/Navbar";
import useLocalStorage from "./useLocalStorage";
import { Lang } from "~/types";

type Props = {
  children?: React.ReactNode;
  title: string;
};

export const useNavbarWithLang = () => {
  const [lang, setLang] = useLocalStorage<Lang>("lang", "en");

  const NavbarWithLang = ({ children, title }: Props) => (
    <Navbar title={title}>
      {children}
      <LangSelect
        defaultLang={lang}
        handleLangChange={(selectedLang) => setLang(selectedLang)}
      />
    </Navbar>
  );

  return { Navbar: NavbarWithLang, lang };
};

export default useNavbarWithLang;
