import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Lang } from "~/types";
import { KptnSelect } from "./KptnSelect";

type Props = {
  handleLangChange: (selectedLang: Lang) => void;
  defaultLang: Lang;
};

export default function LangSelect({ handleLangChange, defaultLang }: Props) {
  const [lang, setLang] = React.useState(defaultLang);

  const languages = [
    <MenuItem key="en" value={"en"}>
      EN
    </MenuItem>,
    <MenuItem key="de" value={"de"}>
      DE
    </MenuItem>,
    <MenuItem key="es" value={"es"}>
      ES
    </MenuItem>,
    <MenuItem key="fr" value={"fr"}>
      FR
    </MenuItem>,
    <MenuItem key="pt" value={"pt"}>
      PT
    </MenuItem>,
  ];

  return (
    <FormControl variant="outlined">
      <Select
        variant="standard"
        value={lang}
        onChange={(event) => {
          handleLangChange(event.target.value as Lang);
          setLang(event.target.value as Lang);
        }}
        input={<KptnSelect />}
      >
        {languages}
      </Select>
    </FormControl>
  );
}
