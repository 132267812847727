import { useEffect, useState, Dispatch, SetStateAction } from "react";

interface UseLocalStorageOptions<T> {
  serialize?: (value: T) => string;
  deserialize?: (value: string) => T;
}

type UseLocalStorageReturnType<T> = [T, Dispatch<SetStateAction<T>>];

const useLocalStorage = <T>(
  key: string,
  defaultValue: T = {} as T,
  {
    serialize = JSON.stringify,
    deserialize = JSON.parse,
  }: UseLocalStorageOptions<T> = {}
): UseLocalStorageReturnType<T> => {
  const [state, setState] = useState(defaultValue);

  const getValueFromLocalStorage = () => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage);
    }
    return defaultValue;
  };

  useEffect(() => {
    const value = getValueFromLocalStorage();
    setState(value);
  }, [typeof window]);

  useEffect(() => {
    window.localStorage.setItem(key, serialize(state));
  }, [key, state, serialize]);

  return [state, setState];
};
export default useLocalStorage;
