import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Option } from "~/types";
import { clsx } from "clsx";

type Props<CustomOption extends Option> = {
  options: CustomOption[];
  onChange: (value?: CustomOption) => void;
  label?: string;
  className?: string;
  getOptionLabel?: (option: CustomOption) => string;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: CustomOption;
  isOptionEqualToValue?: (option: CustomOption, value: CustomOption) => boolean;
};

function SimpleAutoComplete<CustomOption extends Option = Option>({
  disabled = false,
  ...props
}: Props<CustomOption>) {
  const getOptionLabel = (option: CustomOption) => {
    return option.value;
  };
  const [value, setValue] = React.useState<CustomOption | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <>
      <div
        className={clsx(
          props.className,
          "rounded border-[0.5px] border-gray-500 m-4"
        )}
      >
        <Autocomplete
          disabled={disabled}
          defaultValue={props.defaultValue}
          value={value}
          onChange={(_: any, newValue: CustomOption | null) => {
            setValue(newValue);

            if (newValue) {
              props.onChange(newValue);
            } else {
              props.onChange();
            }
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={props.options}
          getOptionLabel={getOptionLabel}
          getOptionKey={(option) => option.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              placeholder={props.placeholder}
            />
          )}
          isOptionEqualToValue={
            props.isOptionEqualToValue ??
            ((option, value) => option.id === value.id)
          }
        />
      </div>
    </>
  );
}

export default SimpleAutoComplete;
