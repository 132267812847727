import { InputBase } from "@mui/material";
import { withStyles } from "tss-react/mui";

export const KptnSelect = withStyles(InputBase, (theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    border: "none",
    borderRadius: 4,
    backgroundColor: "transparent",
    letterSpacing: "0.8px",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "AlianzaSlab700",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      border: "none",
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "none",
    },
  },
}));
