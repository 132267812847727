import { useEffect } from "react";
import { useNavigation, useRevalidator } from "@remix-run/react";

const useResetForm = (reset) => {
  const { state } = useNavigation();
  const revalidator = useRevalidator();

  useEffect(() => {
    reset({}, { keepValues: true, keepDirty: false });
  }, [state, revalidator.state]);
};

export default useResetForm;
