import { Tooltip as MUITooltip, Icon } from "@mui/material";

type Props = {
  title: string;
};

const Tooltip = ({ title = "" }: Props) => {
  return (
    <MUITooltip title={title} placement="top-start">
      <Icon
        style={{
          marginLeft: "5px",
          fontSize: "16px",
          marginBottom: "-2px",
          color: "rgb(54, 52, 49, 0.8)",
        }}
      >
        info
      </Icon>
    </MUITooltip>
  );
};

export default Tooltip;
