import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = { onClose: () => void; text: string; children?: React.ReactNode };

const ModalHeader = ({ onClose, text, children }: Props) => (
  <>
    <div className="flex items-center justify-between gap-2">
      <div className="pageTitle text-primary-100">{text}</div>
      <HighlightOffIcon
        onClick={onClose}
        className="cursor-pointer hover:text-primary-100"
      />
    </div>
    {children}
  </>
);

export default ModalHeader;
