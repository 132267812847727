import { useEffect, useMemo } from "react";
import NProgress from "nprogress";

const useNProgress = (transition, fetchers, revalidator) => {
  const currentState = useMemo(() => {
    const states = [
      transition.state,
      ...fetchers.map((fetcher) => fetcher.state),
      revalidator.state,
    ];
    if (states.every((state) => state === "idle")) return "idle";
    return "loading";
  }, [transition.state, fetchers, revalidator]);

  useEffect(() => {
    if (currentState === "loading") NProgress.start();
    if (currentState === "idle") NProgress.done();
  }, [currentState]);
};

export default useNProgress;
