import { useEffect } from "react";

const useSetDataToLocalStorage = (name: string, data: string | undefined) => {
  useEffect(() => {
    if (window && data) {
      window.localStorage.setItem(name, data);
    }
  }, [data]);
};

export default useSetDataToLocalStorage;
