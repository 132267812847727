import { useRevalidator } from "@remix-run/react";

import type { Image } from "~/types";
import useToastWithState from "~/hooks/useToastWithState";
import { ApiResponse, createToastMessage } from "~/server/utils";
import ImageGrid from "./ImageGrid";

type Props = {
  image?: Image;
  isUpdatable?: boolean;
  isDisabled?: boolean;
  span?: string;
  uploadFunction: (formdata: FormData) => Promise<ApiResponse<unknown>>;
  deleteFunction: () => Promise<ApiResponse<unknown>>;
};

const ImagePicker = ({
  image,
  isUpdatable = false,
  isDisabled = false,
  span = "12",
  uploadFunction,
  deleteFunction,
}: Props) => {
  const revalidator = useRevalidator();

  const defaultImages = image ? [image] : [];
  const showToast = useToastWithState();

  const handleUploadImage = async (images: File[]) => {
    const formData = new FormData();
    formData.append("image", images[0]);

    const res = await uploadFunction(formData);
    const toastMessage = createToastMessage(res, "Image Uploaded");

    if (!res.failure) {
      revalidator.revalidate();
    }

    showToast(toastMessage);
  };

  const handleDeleteImage = async () => {
    const res = await deleteFunction();
    const toastMessage = createToastMessage(res, "Image Deleted");

    if (!res.failure) {
      revalidator.revalidate();
    }

    showToast(toastMessage);
  };

  return (
    <ImageGrid
      key={image?.url}
      span={span}
      isUpdatable={isUpdatable}
      handleDrop={handleUploadImage}
      defaultImages={defaultImages}
      handleDelete={handleDeleteImage}
      isDisabled={isDisabled}
    />
  );
};

export default ImagePicker;
