import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { CancelBtn, PrimaryBtn } from "../components";
import {
  executeRequest,
  getErrorHandler,
  getThrowHandler,
} from "~/utils/api_utils";
import { useRevalidator } from "@remix-run/react";
import { Toast } from "~/types";
import useToast from "./useToast";

type useConfirmModalReturn = [() => void, () => JSX.Element];

const useConfirmModal = (
  requestFn: (...args: string[]) => Promise<unknown>,
  deleteArgs: string[],
  name: string,
  confirmMsg: string = `Delete ${name}?`,
  toastMsg: string = `${name} Deleted`,
  errorMsg?: string
): useConfirmModalReturn => {
  const { revalidate } = useRevalidator();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast>();

  const showConfirmModal = () => setShowModal(true);

  const onConfirm = async () => {
    const msg = await executeRequest(
      () => requestFn(...deleteArgs),
      getThrowHandler(),
      toastMsg,
      getErrorHandler(errorMsg)
    );
    revalidate();

    setShowModal(false);
    setToast(msg);
  };

  useToast(toast);

  const DeleteModal = () => (
    <Dialog open={showModal}>
      <DialogContent>
        <DialogContentText>
          <span className="text-md">{confirmMsg}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex flex-col gap-2 flex-grow">
          <PrimaryBtn onClick={onConfirm} text="Confirm" />
          <CancelBtn onClick={() => setShowModal(false)} />
        </div>
      </DialogActions>
    </Dialog>
  );

  return [showConfirmModal, DeleteModal];
};

export default useConfirmModal;
