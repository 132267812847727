import { useFormState } from "react-hook-form";
import { SaveBtn } from "~/components/Buttons";

type Props = {
  isSubmitting: boolean;
  disabled?: boolean;
  name: string;
  text: string;
  value: string;
};

const SaveButton = ({ isSubmitting, disabled, name, text, value }: Props) => {
  const { dirtyFields, isValid } = useFormState();

  const isDirty = () => {
    return isValid && Object.keys(dirtyFields).length > 0;
  };

  return (
    <SaveBtn
      isSubmitting={isSubmitting}
      disabled={disabled || !isDirty()}
      text={text}
      name={name}
      value={value}
    />
  );
};

export default SaveButton;
